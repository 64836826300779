<template>
  <div :class="classOut">
    <div class="card-body">
      <div class="d-flex justify-content-between mb-5">
        <div>
          <h5 class="box-title mb-0">{{ title }}</h5>
          <div class="text-muted font-13">{{ subtitle }}</div>
        </div>
        <a  v-if="moreInfoUrl" class="text-muted" :href="moreInfoUrl"><i class="ti-more-alt"></i></a>
      </div>
      <div class="flexbox mb-2">
        <div class="h1 mb-0">
          {{ prefix }} {{ showNumber(value) }}{{ sufix }}
        </div>
        <span class="text-success font-18" :class="{ 'text-success': percent >= 0, 'text-danger': percent < 0 }">
          <i class="mr-2" :class="{ 'ft-arrow-up-right': percent >= 0, 'ft-arrow-down-left': percent < 0 }"></i>
          {{ numberFormat(getPercent, 2,',','.') }}%
        </span>
      </div>
      <div class="text-muted">{{ description }}</div>
    </div>
  </div>

</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

export default {
  name: 'SummaryCard',
  props: {
    classOut: {
      type: String,
      required: false,
      default: 'card card-fullheight'
    },
    valueFormatter: {
      type: Function,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    sufix: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: Number,
      required: true,
      default: 0
    },
    percent: {
      type: Number,
      required: true,
      default: 0
    },
    moreInfoUrl: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    showNumber (number, thousandsSep, defaultValue) {
      if (this.valueFormatter) {
        return this.valueFormatter(number)
      }

      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      if (number !== parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },

    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    }
  },
  computed: {
    getPercent () {
      if (this.percent < 0) {
        return this.percent * -1.0
      }
      return this.percent
    }
  }
}

</script>

<style scoped>
  .text-success {
    color: #3c763d;
  }
  .h1 {
    font-size: 2.2rem;
  }
</style>
